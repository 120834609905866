import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: 'Home',
        component: () => import("../pages/Home")
    },
    {
        path: "/home",
        name: 'Home',
        component: () => import("../pages/Home")
    },
    {
        path: "/clientes",
        name: 'Clientes',
        component: () => import("../pages/Home")
    },
    {
        path: "/sobre",
        name: 'Sobre',
        component: () => import("../pages/Home")
    },
    {
        path: "/servicos",
        name: 'Servicos',
        component: () => import("../pages/Home")
    },
    {
        path: "/trabalhos",
        name: 'Trabalhos',
        component: () => import("../pages/Home")
    },
    {
        path: "/contatos",
        name: 'Contato',
        component: () => import("../pages/Home")
    },
    {
            path: "/enc-painel/login",
        name: 'painel.login',
        component: () => import("../pages/painel/login/Login")
    },
    {
        path: "/enc-painel/",
        name: "painel",
        component: () => import("../pages/painel/LayoutInternas"),
        children: [
            {
                path: "/enc-painel/",
                name: 'painel.home',
                component: () => import("../pages/painel/Home")
            },
            {
                path: "/enc-painel/taxonomia",
                name: 'painel.taxonomia',
                component: () => import("../pages/painel/taxonomia/Taxonomias")
            },
            {
                path: "/enc-painel/taxonomia/:id",
                name: 'painel.taxonomia.id',
                component: () => import("../pages/painel/taxonomia/Taxonomia")
            },
            {
                path: "/enc-painel/job",
                name: 'painel.job',
                component: () => import("../pages/painel/job/Jobs")
            },
            {
                path: "/enc-painel/job/new",
                name: 'painel.job.new',
                component: () => import("../pages/painel/job/New")
            },
            {
                path: "/enc-painel/job/:id",
                name: 'painel.job.id',
                component: () => import("../pages/painel/job/Job")
            },
            {
                path: "/enc-painel/imagens",
                name: 'painel.imagem',
                component: () => import("../pages/painel/imagens/Imagem")
            },
        ]
    },

];

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
